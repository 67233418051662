import React from 'react';
import './BookCard.css';

const BookCard = ({ book, index }) => {
  const { title, author, book_cover } = book;

  // Определяем цвет на основе индекса
  const colorClasses = ['color-1', 'color-2', 'color-3', 'color-4'];
  const colorClass = colorClasses[index % colorClasses.length];

  return (
    <div className={`book-card ${colorClass}`}>
      <div className="book-card__image-wrapper">
        <img
          src={book_cover || '/images/placeholder_book.png'}
          alt={title}
          className="book-card__image"
        />
      </div>
      <div className="book-card__content">
        <h3 className="book-card__title">{title}</h3>
        <p className="book-card__author">{author}</p>
      </div>
    </div>
  );
};

export default BookCard;
