import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { AuthProvider } from './context/AuthContext'; // импортируем наш провайдер

// Find the root element in your HTML file
const rootElement = document.getElementById('root');

if (rootElement) {
  // Use createRoot instead of ReactDOM.render
  const root = createRoot(rootElement);
  root.render(
  <AuthProvider>
    <App />
  </AuthProvider>
  );
} else {
  console.error("Root element not found");
}
