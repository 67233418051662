import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';
import { AuthContext } from '../../context/AuthContext';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { accessToken, logout, username, currentUserId } = useContext(AuthContext);
  const navigate = useNavigate();

  return (
    <nav className="navbar">
      {/* Логотип */}
      <Link to="/" className="navbar__logo">
        <img src="/images/reading-book.png" alt="Bookclub Logo" className="logo-image" />
        <div className="colored-logo">
          <span>Book</span>
          <span>lub</span>
        </div>
      </Link>

      {/* Кнопки на широком экране */}
      <div className="navbar__actions">
        {!accessToken ? (
          <>
            <Link to="/auth/register" className="navbar__button">
              Регистрация
            </Link>
            <Link to="/auth/login" className="navbar__button navbar__button--secondary">
              Войти
            </Link>
          </>
        ) : (
          <>
            {/* Кнопка с именем пользователя */}
            <button 
              className="navbar__button"
              onClick={() => navigate(`/users/${currentUserId}`)}
            >
              {username}
            </button>
            {/* Кнопка выхода */}
            <button
              className="navbar__button navbar__button--secondary"
              onClick={() => {
                logout();
                navigate('/auth/login'); // Перенаправляем на страницу логина
              }}
            >
              Выйти
            </button>
          </>
        )}
      </div>

      {/* Гамбургер-меню */}
      <div className="navbar__toggle" onClick={() => setMenuOpen(!menuOpen)}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>

      {/* Мобильное меню */}
      {menuOpen && (
        <div className="navbar__menu">
          <span className="navbar__close" onClick={() => setMenuOpen(false)}>
            &times;
          </span>
          <ul className="navbar__links">
            {!accessToken ? (
              <>
                <li>
                  <Link to="/auth/register" className="navbar__button" onClick={() => setMenuOpen(false)}>
                    Регистрация
                  </Link>
                </li>
                <li>
                  <Link to="/auth/login" className="navbar__button navbar__button--secondary" onClick={() => setMenuOpen(false)}>
                    Войти
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li>
                  <button
                    className="navbar__button"
                    onClick={() => {
                      navigate(`/users/${currentUserId}`);
                      setMenuOpen(false);
                    }}
                  >
                    {username}
                  </button>
                </li>
                <li>
                  <button
                    className="navbar__button navbar__button--secondary"
                    onClick={() => {
                      logout();
                      setMenuOpen(false);
                      navigate('/auth/login');
                    }}
                  >
                    Выйти
                  </button>
                </li>
              </>
            )}
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
