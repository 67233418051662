import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './ProfileEditPage.css';
import { AuthContext } from '../context/AuthContext';

const ProfileEditPage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { userId } = useParams();
  const parsedUserId = parseInt(userId, 10);
  const navigate = useNavigate();
  const { accessToken, currentUserId } = useContext(AuthContext);

  const [profileData, setProfileData] = useState({
    first_name: '',
    last_name: '',
    city: '',
    photo: null,
    about_me: '',
    current_reading_photo: null,
    current_reading_title: '',
    current_reading_author: '',
    current_reading_description: '',
    user_id: parsedUserId,
  });

  const [previewImage, setPreviewImage] = useState(null);
  const [bookPreviewImage, setBookPreviewImage] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [photoError, setPhotoError] = useState('');
  const [bookPhotoError, setBookPhotoError] = useState('');

  useEffect(() => {
    if (!accessToken) {
      setErrorMessage('Чтобы редактировать профиль, нужно быть авторизованным пользователем.');
      return;
    }

    if (parsedUserId !== currentUserId) {
      setErrorMessage('Вы не можете редактировать чужой профиль.');
      return;
    }

    fetch(`${apiUrl}/v1/users/${parsedUserId}/profile`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Ошибка при загрузке данных профиля');
        }
        return response.json();
      })
      .then((data) => {
        setProfileData(data);
        setPreviewImage(data.photo ? `${apiUrl}/uploads/${data.photo}` : null);
        setBookPreviewImage(data.current_reading_photo ? `${apiUrl}/uploads/${data.current_reading_photo}` : null);
      })
      .catch((error) => {
        console.error('Ошибка при загрузке данных профиля:', error);
        setErrorMessage('Ошибка при загрузке данных профиля.');
      });
  }, [parsedUserId, currentUserId, apiUrl, accessToken]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    const maxSize = 1048576; // 1 МБ в байтах
    if (!file) return;

    if (file.size > maxSize) {
      setPhotoError('Файл слишком большой. Максимальный размер — 1 МБ.');
      return;
    }

    setPhotoError(''); // Сбрасываем ошибку, если размер файла допустим

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch(`${apiUrl}/v1/files/upload/`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Ошибка при загрузке файла.');
      }

      const data = await response.json();

      if (!data.key) {
        throw new Error('Ключ файла отсутствует в ответе сервера.');
      }

      setProfileData((prevState) => ({
        ...prevState,
        photo: data.key,
      }));

      setPreviewImage(`${process.env.REACT_APP_S3_BASE_URL}/${data.key}`);
    } catch (error) {
      console.error('Ошибка при загрузке изображения:', error);
      setPhotoError('Ошибка при загрузке изображения.');
    }
  };

  const handleBookImageUpload = async (e) => {
    const file = e.target.files[0];
    const maxSize = 1048576; // 1 МБ в байтах
    if (!file) return;

    if (file.size > maxSize) {
      setBookPhotoError('Файл слишком большой. Максимальный размер — 1 МБ.');
      return;
    }

    setBookPhotoError(''); // Сбрасываем ошибку, если размер файла допустим

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch(`${apiUrl}/v1/files/upload/`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Ошибка при загрузке файла.');
      }

      const data = await response.json();

      if (!data.key) {
        throw new Error('Ключ файла отсутствует в ответе сервера.');
      }

      setProfileData((prevState) => ({
        ...prevState,
        current_reading_photo: data.key,
      }));

      setBookPreviewImage(`${process.env.REACT_APP_S3_BASE_URL}/${data.key}`);
    } catch (error) {
      console.error('Ошибка при загрузке изображения книги:', error);
      setBookPhotoError('Ошибка при загрузке изображения книги.');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const profileDataToSend = {
      first_name: profileData.first_name,
      last_name: profileData.last_name,
      city: profileData.city,
      about_me: profileData.about_me,
      current_reading_title: profileData.current_reading_title,
      current_reading_author: profileData.current_reading_author,
      current_reading_description: profileData.current_reading_description,
      photo: profileData.photo,
      current_reading_photo: profileData.current_reading_photo,
    };

    fetch(`${apiUrl}/v1/users/${parsedUserId}/profile`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(profileDataToSend),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Ошибка при обновлении профиля');
        }
        return response.json();
      })
      .then(() => {
        setSuccessMessage('Профиль успешно обновлен!');
        setTimeout(() => {
          navigate(`/users/${parsedUserId}`);
        }, 2000);
      })
      .catch((error) => {
        console.error('Ошибка при обновлении профиля:', error);
        setErrorMessage('Ошибка при обновлении профиля.');
      });
  };

  if (errorMessage) {
    return (
      <div className="error-message-container">
        <p className="error-message">{errorMessage}</p>
        <button onClick={() => navigate(`/users/${currentUserId}`)}>Вернуться к своему профилю</button>
      </div>
    );
  }

  return (
    <div className="profile-edit-page">
      <h1 className="edit-profile-title">Редактировать профиль</h1>
      {successMessage && <p className="success-message">{successMessage}</p>}
      <form onSubmit={handleSubmit} className="profile-edit-form">
        <div className="form-group">
          <label htmlFor="first_name">Имя:</label>
          <input
            type="text"
            id="first_name"
            name="first_name"
            value={profileData.first_name}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label htmlFor="last_name">Фамилия:</label>
          <input
            type="text"
            id="last_name"
            name="last_name"
            value={profileData.last_name}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label htmlFor="city">Город:</label>
          <input
            type="text"
            id="city"
            name="city"
            value={profileData.city}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label htmlFor="about_me">Обо мне:</label>
          <textarea
            id="about_me"
            name="about_me"
            value={profileData.about_me}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label htmlFor="current_reading_title">Название книги:</label>
          <input
            type="text"
            id="current_reading_title"
            name="current_reading_title"
            value={profileData.current_reading_title}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label htmlFor="current_reading_author">Автор книги:</label>
          <input
            type="text"
            id="current_reading_author"
            name="current_reading_author"
            value={profileData.current_reading_author}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label htmlFor="current_reading_description">Описание книги:</label>
          <textarea
            id="current_reading_description"
            name="current_reading_description"
            value={profileData.current_reading_description}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label htmlFor="photo">Фото профиля:</label>
          <input
            type="file"
            id="photo"
            onChange={handleImageUpload}
            className="form-control"
          />
          {photoError && <p className="error-message">{photoError}</p>}
          {previewImage && (
            <img src={previewImage} alt="Предварительный просмотр" className="profile-preview-img" />
          )}
        </div>
        <div className="form-group">
          <label htmlFor="book_photo">Фото книги:</label>
          <input
            type="file"
            id="book_photo"
            onChange={handleBookImageUpload}
            className="form-control"
          />
          {bookPhotoError && <p className="error-message">{bookPhotoError}</p>}
          {bookPreviewImage && (
            <img src={bookPreviewImage} alt="Предварительный просмотр книги" className="profile-preview-img" />
          )}
        </div>
        <div className="button-group">
          <button type="button" className="cancel-button" onClick={() => navigate(`/users/${parsedUserId}`)}>Отмена</button>
          <button type="submit" className="save-button">Сохранить изменения</button>
        </div>
      </form>
    </div>
  );
};

export default ProfileEditPage;
