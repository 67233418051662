import React, { useState, useContext } from 'react';
import './Register.css';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { decodeToken } from '../../utils/auth';

const Register = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [name, setName] = useState('');
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(null);
  const [telegram, setTelegram] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  const checkUsernameAvailability = async (username) => {
    try {
      const response = await fetch(`${apiUrl}/v1/check_username?username=${username}`);
      setIsUsernameAvailable(response.ok);
    } catch {
      setError('Ошибка сети при проверке имени, попробуйте еще раз');
    }
  };

  const handleUsernameChange = (e) => {
    const value = e.target.value;
    setName(value);
    if (value.length > 3) {
      checkUsernameAvailability(value);
    } else {
      setIsUsernameAvailable(null);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      return setError('Пароли не совпадают');
    }

    if (isUsernameAvailable === false) {
      return setError('Имя пользователя уже занято');
    }

    setError('');

    try {
      // Отправляем запрос на регистрацию
      const registerResponse = await fetch(`${apiUrl}/v1/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: name,
          telegram: telegram,
          email: email,
          password: password,
        }),
      });

      if (!registerResponse.ok) {
        const errorData = await registerResponse.json();
        return setError(errorData.detail || 'Ошибка регистрации');
      }

      // Если регистрация прошла успешно, пробуем сразу же залогиниться
      const loginResponse = await fetch(`${apiUrl}/v1/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({ username: name, password: password }),
      });

      if (!loginResponse.ok) {
        return setError(
          'Не удалось автоматически авторизоваться после регистрации, попробуйте войти вручную.'
        );
      }

      const loginData = await loginResponse.json();
      // Декодирование токена
      const decoded = decodeToken(loginData.access_token);
      const userId = decoded.user_id;  // предполагается, что user_id закодирован в токене
      const usernameFromToken = decoded.sub; // поле 'sub' стандартно для username

      // Вызываем login — Порядок: (accessToken, userId, username, refreshToken)
      login(loginData.access_token, userId, usernameFromToken, loginData.refresh_token);

      // Редирект на страницу профиля
      navigate(`/users/${userId}`);
    } catch {
      setError('Ошибка сети, попробуйте еще раз');
    }
  };

  return (
    <div className="register-container">
      <div className="register-form-container">
        <div className="register-form">
          <h1 className="register-title">Регистрация</h1>
          <form onSubmit={handleRegister}>
            <div className="register-input-group">
              <label>Имя пользователя</label>
              <input
                type="text"
                placeholder="Введите ваше имя пользователя"
                value={name}
                onChange={handleUsernameChange}
                required
              />
              {isUsernameAvailable === false && (
                <p className="error-message">Имя пользователя уже занято</p>
              )}
            </div>
            <div className="register-input-group">
              <label>Телеграм</label>
              <input
                type="text"
                placeholder="Введите ваш Telegram ник"
                value={telegram}
                onChange={(e) => setTelegram(e.target.value)}
              />
            </div>
            <div className="register-input-group">
              <label>Электронная почта *</label>
              <input
                type="email"
                placeholder="Введите вашу почту"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="register-input-group">
              <label>Пароль *</label>
              <input
                type="password"
                placeholder="Введите ваш пароль"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="register-input-group">
              <label>Повторите пароль *</label>
              <input
                type="password"
                placeholder="Повторите ваш пароль"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            {error && <p className="error-message">{error}</p>}
            <button type="submit" className="register-button">
              Зарегистрироваться
            </button>
          </form>
        </div>
        <div className="register-image">
          <img src="/images/clubs.jpeg" alt="Books" />
        </div>
      </div>
    </div>
  );
};

export default Register;
