import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import './AboutMe.css';
import '../../index.css'; 
import Modal from './Modal';
import BookCard from '../book/BookCard';

const AboutClub = ({
  bookTitle,
  bookAuthor,
  bookDescription,
  bookImage,
  clubName,
  clubDescription,
  onJoinClub,
  currentBookHeading = "Читаем сейчас",    // Заголовок для текущей книги
  aboutSectionHeading = "О клубе",         // Заголовок для секции "О клубе"
}) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleJoinSubmit = (data) => {
    console.log("User info submitted:", data);
  };

  // Создаем объект книги для передачи в BookCard
  const currentBook = {
    title: bookTitle || 'Название недоступно',
    author: bookAuthor || 'Автор неизвестен',
    book_cover: bookImage || '/images/default_book_cover.png',
  };

  return (
    <section className="about-club-section">
      <div className="about-club-content">
        {/* Блок "Читаем сейчас" */}
        <div className="current-book-section">
          {/* <h2>Читаем сейчас</h2> */}
          <h2>{currentBookHeading}</h2>
          <div className="current-book-info">
            <BookCard book={currentBook} index={1}/>
            {/* {bookDescription && (
              <p className="book-description">{bookDescription}</p>
            )} */}
          </div>
        </div>

        {/* Блок "О клубе" */}
        <div className="about-club-info">
          {/* <h2>О клубе</h2> */}
          <h2>{aboutSectionHeading}</h2>
          <div
            className="club-description"
            dangerouslySetInnerHTML={{ __html: clubDescription }}
          />
          {/* Кнопку выводим только если есть onJoinClub */}
          {onJoinClub && (
            <button className="button-standard" onClick={() => setModalOpen(true)}>
              Присоединиться к клубу
            </button>
          )}
        </div>
      </div>

      {isModalOpen && (
        <Modal
          onClose={() => setModalOpen(false)}
          onSubmit={handleJoinSubmit}
          title="Присоединиться"
          description="Будем рады видеть Вас в нашем клубе. Заполните, пожалуйста, заявку. В поле 'О себе' Вы можете немного рассказать нам про Вас."
          fields={[
            { name: "name", placeholder: "Имя", type: "text" },
            { name: "telegram", placeholder: "Телеграм", type: "text" },
            { name: "about_me", placeholder: "О себе", type: "text" }
          ]}
          buttonLabel="Присоединиться"
          messagePrefix={`[ПРИСОЕДИНИТЬСЯ К КЛУБУ "${clubName}"]`}
        />
      )}
    </section>
  );
};

export default AboutClub;
