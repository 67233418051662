import React, { createContext, useState } from 'react';

// Создаем сам контекст
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [username, setUsername] = useState(null);

  // Логин
  const login = (token, userId, usernameValue, refreshTokenValue) => {
    setAccessToken(token);
    setRefreshToken(refreshTokenValue);
    setCurrentUserId(userId);
    setUsername(usernameValue);

    localStorage.setItem('accessToken', token);
    localStorage.setItem('refreshToken', refreshTokenValue);
    localStorage.setItem('currentUserId', userId);
    localStorage.setItem('username', usernameValue);

    console.log('[AuthContext] Logged in');
  };

  // Логаут
  const logout = () => {
    setAccessToken(null);
    setRefreshToken(null);
    setCurrentUserId(null);
    setUsername(null);

    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('currentUserId');
    localStorage.removeItem('username');
    console.log('[AuthContext] Logged out');
  };

  // Читаем токены из localStorage при монтировании
  React.useEffect(() => {
    const storedToken = localStorage.getItem('accessToken');
    const storedRefreshToken = localStorage.getItem('refreshToken');
    const storedUserId = localStorage.getItem('currentUserId');
    const storedUsername = localStorage.getItem('username');

    if (storedToken && storedRefreshToken && storedUserId && storedUsername) {
      setAccessToken(storedToken);
      setRefreshToken(storedRefreshToken);
      setCurrentUserId(Number(storedUserId));
      setUsername(storedUsername);
    }
  }, []);

  /**
   * Рефреш access-токена
   * Возвращает новый токен или null, если рефреш не удался
   */
  const refreshAccessToken = async () => {
    if (!refreshToken) return null;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/refresh`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ refresh_token: refreshToken }),
        }
      );

      if (!response.ok) {
        console.log('[AuthContext] Не удалось обновить токен, делаем logout');
        logout();
        return null;
      }

      const data = await response.json();
      const newAccess = data.access_token;

      setAccessToken(newAccess);
      localStorage.setItem('accessToken', newAccess);

      if (data.refresh_token) {
        setRefreshToken(data.refresh_token);
        localStorage.setItem('refreshToken', data.refresh_token);
      }

      console.log('[AuthContext] Token refreshed successfully');
      return newAccess;
    } catch (err) {
      console.error('[AuthContext] Ошибка обновления токена:', err);
      logout();
      return null;
    }
  };

  // Обёртка вокруг fetch, перехватывающая 401
  const authorizedFetch = async (url, options = {}) => {
    if (!accessToken) {
      const gotToken = await refreshAccessToken();
      if (!gotToken) {
        return new Response(null, { status: 401 });
      }
    }

    let headers = {
      ...options.headers,
      Authorization: `Bearer ${accessToken}`,
    };

    let response = await fetch(url, { ...options, headers });

    if (response.status === 401) {
      console.log('[AuthContext] Получен 401, пробуем рефрешнуть');
      const newToken = await refreshAccessToken();

      if (!newToken) {
        return response;
      }

      headers = {
        ...options.headers,
        Authorization: `Bearer ${newToken}`,
      };
      response = await fetch(url, { ...options, headers });

      if (response.status === 401) {
        console.log('[AuthContext] Повторный 401 — выходим в logout');
        logout();
      }
    }

    return response;
  };

  const value = {
    accessToken,
    refreshToken,
    currentUserId,
    username,
    login,
    logout,
    refreshAccessToken,
    authorizedFetch,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
